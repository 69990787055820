<template>
  <div class="d-flex" style="min-height: 100vh">
    <div class="pa-4" style="width: 300px; flex-shrink: 0">
      <div class="text-h6 ap-orange--text">DupeGrid Configurations</div>

      <div class="d-flex">
        <v-switch v-model="isNewGridEnabled" label="Show New Grid" />
      </div>

      <v-select
        v-model="entityName"
        :items="entityNames"
        label="Entity Name"
        outlined
        dense
        hide-details
        class="mb-4"
      />

      <v-select
        v-model="mode"
        :items="modes"
        label="Mode"
        outlined
        dense
        hide-details
        class="mb-4"
        @change="resetValues"
      />

      <v-card outlined class="mt-3 pa-3">
        <v-text-field
          v-if="mode === 'history'"
          v-model="dupeSetId"
          label="DupeSet Id"
          hide-details
          outlined
          dense
          class="mb-4"
        />

        <v-text-field
          v-model="entityId"
          label="Entity Id"
          hide-details
          outlined
          dense
          class="mb-4"
        />

        <div class="d-flex justify-end">
          <v-btn
            small
            color="ap-orange"
            class="ap-white--text"
            :disabled="!isButtonEnabled"
            @click="showDupeGrid(null)"
          >
            Get it!
          </v-btn>
        </div>
      </v-card>

      <template v-if="mode === 'duplicate'">
        <div
          class="py-3 my-4 text-center font-weight-medium"
          style="position: relative"
        >
          <v-divider />
          <div
            style="position: absolute; top: 0; bottom: 0; right: 0; left: 0"
            class="d-flex align-center justify-center"
          >
            <span class="white px-3">Or</span>
          </div>
        </div>

        <v-card outlined class="pa-3">
          <v-text-field
            v-model="searchString"
            label="Search by Name"
            hide-details
            outlined
            dense
            class="mb-4"
          />

          <div class="d-flex justify-end">
            <v-btn
              small
              color="ap-orange"
              class="ap-white--text"
              :disabled="!searchString"
              :loading="isLoadingResults"
              @click="searchByName"
            >
              Search
            </v-btn>
          </div>
        </v-card>
      </template>
    </div>

    <v-divider vertical />

    <template v-if="searchResults && mode === 'duplicate'">
      <div
        class="px-1"
        style="
          width: 300px;
          max-height: 100vh;
          flex-shrink: 0;
          overflow-y: auto;
        "
      >
        <div class="text-h6 ap-orange--text px-4 pt-4">
          {{ searchEntity }} Matches
        </div>

        <ApLoading v-if="isLoadingResults" />

        <div v-else-if="searchResults.length === 0" class="px-4">
          No results!
        </div>

        <v-list v-else class="ma-0" style="width: 100%">
          <v-list-item-group v-model="selectedResultId">
            <template v-for="result in searchResults">
              <v-list-item
                :key="result.Id"
                two-line
                :value="result.Id"
                @click="showDupeGrid(result.Id)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ result.Name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ result.Id }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>$mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="`divider_${result.Id}`" />
            </template>
          </v-list-item-group>
        </v-list>
      </div>

      <v-divider vertical />
    </template>

    <div class="flex-grow-1 px-1" style="max-height: 100vh; overflow-y: auto">
      <DupesGridIframe
        v-if="show"
        :mode="dupeSetInfo.mode"
        :dupe-set="{
          id: dupeSetInfo.dupeSetId,
          primaryId: dupeSetInfo.primaryId,
          source: dupeSetInfo.source,
        }"
      />

      <template v-if="isNewGridEnabled">
        <ApLoading v-if="isLoadingNewGrid" text="Loading New Grid" />
        <DupeGrid
          v-if="response"
          :entity-id="dupeSetInfo.primaryId"
          :entity-name="dupeSetInfo.source"
          :crm-current-url="crmCurrentUrl"
          :user-email-logged-in="userEmailLoggedIn"
          :response="response"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DupesGridIframe from '@/components/dupesgrid/DupesGridIframe'
import ApLoading from '@/components/common/ApLoading'
import DupeGrid from '@/components/dupegrid/DupeGrid'
import { getEntities } from '@/api/dev'
import { getDupeSet } from '@/api/dupe-sets/dupe-sets'
import { mapState } from 'vuex'

export default {
  components: {
    DupesGridIframe,
    ApLoading,
    DupeGrid,
  },
  data() {
    return {
      searchString: null,
      searchEntity: null,
      searchResults: null,
      selectedResultId: null,
      dupeSetId: null,
      entityId: null,
      entityName: 'Account',
      mode: 'duplicate',
      show: false,
      entityNames: ['Account', 'Contact', 'Lead'],
      modes: ['duplicate', 'history'],
      isLoadingResults: false,
      dupeSetInfo: {},
      response: null,
      isNewGridEnabled: false,
      isLoadingNewGrid: false,
    }
  },
  computed: {
    ...mapState('auth', ['agentId', 'contextMessage']),
    isButtonEnabled() {
      return (
        this.entityId &&
        this.entityName &&
        (this.mode === 'duplicate' || this.dupeSetId)
      )
    },
    userEmailLoggedIn() {
      return this.contextMessage.email
    },
    crmCurrentUrl() {
      return this.contextMessage.url
    },
  },
  watch: {
    isNewGridEnabled(newValue) {
      if (newValue) {
        this.getNewGrid()
      } else {
        this.isNewGridEnabled = false
      }
    },
  },
  created() {
    this.dupeSetId = this.$route.query.dupeSetId
    this.entityId = this.$route.query.entityId

    if (this.$route.query.entityName) {
      this.entityName = this.$route.query.entityName
    }

    if (this.$route.query.mode) {
      this.mode = this.$route.query.mode
    }
  },
  methods: {
    async searchByName() {
      this.isLoadingResults = true
      this.searchEntity = this.entityName
      this.searchResults = await getEntities(this.entityName, this.searchString)
      this.isLoadingResults = false
    },
    async showDupeGrid(selectedResultId) {
      if (selectedResultId) {
        this.selectedResultId = selectedResultId
      } else {
        this.selectedResultId = null
      }

      this.show = false
      setTimeout(() => {
        this.dupeSetInfo = {
          dupeSetId: this.dupeSetId,
          primaryId: this.selectedResultId || this.entityId,
          source: this.entityName,
          mode: this.mode,
        }
        this.show = true
      }, 200)

      if (this.isNewGridEnabled) {
        this.getNewGrid()
      }
    },
    resetValues() {
      this.dupeSetId = null
    },
    async getNewGrid() {
      if (this.selectedResultId || this.entityId) {
        this.isLoadingNewGrid = true
        this.response = null
        this.response = await getDupeSet(this.agentId, {
          dupeSetId: this.dupeSetId,
          entityId: this.selectedResultId || this.entityId,
          entityName: this.entityName,
        })
        this.isLoadingNewGrid = false
      }
    },
  },
}
</script>
